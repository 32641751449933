import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import avatar from './avatar.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FontAwesomeIcon = makeShortcode("FontAwesomeIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>&nbsp;</p>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
  <div style={{
        marginRight: '30px'
      }}>
    <img style={{
          borderRadius: '100%',
          height: '100%',
          width: '180px'
        }} src={avatar}></img>
  </div>
  <div>
    <h4>Hakim Ahmad</h4>
    <p>
      <FontAwesomeIcon icon={['fab', 'twitter']} mdxType="FontAwesomeIcon" />
      &nbsp;<a href="https://twitter.com/HakhimAhmad">Matkim</a>
      <br />
      <FontAwesomeIcon icon={['fab', 'github']} mdxType="FontAwesomeIcon" />
      &nbsp;<a href="https://github.com/Congrammers/">Congrammers</a>
      <br />
      {
            /* <FontAwesomeIcon icon={['fab', 'instagram']} />
               &nbsp;<a href="https://www.instagram.com/"></a>
               <br /> */
          }
      <FontAwesomeIcon icon="at" mdxType="FontAwesomeIcon" />
      &nbsp;<b>hakim@congrammer.com</b>
    </p>
  </div>
    </div>
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      